<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 19:55:40
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-02 15:36:42
-->
<template>
<el-drawer  title="查看协议" :visible.sync="showVisisble" size="90%" @close="cancel">
    <div class="ty-drawer-content">
        <div class="ty-try-header">
            <p v-if="this.name">
                <span><i class="iconfont iconxieyiguanli"></i>{{this.name}}</span>
                <span></span>
            </p>
            <!-- <p></p> -->
        </div>
        <div class="ty-page-list ty-drawer-list">
            <el-table
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#e5f2ff' }"
                    style="width: 100%;margin:0 0 10px;"
                    highlight-current-row
                    max-height='700px'
                    :data="serviceData">
                <el-table-column :show-overflow-tooltip="true"  fixed prop="templateId" label="协议ID"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="200" fixed prop="templateName" label="协议名称"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140" :formatter="formatDate" prop="signTime" label="签订时间"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"   :formatter="formatFormAllData" prop="businessTypeId" label="业务类型"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"   :formatter="formatFormAllData" prop="contractTemplateType" label="协议类型"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"   prop="contractTemplateAreaName" label="协议地区"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"   :formatter="formatFormAllData" prop="status" label="协议审核"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true" width="140" :formatter="formatDate" prop="checkTime" label="审核时间">
<!--                    <template slot-scope="scope">-->
<!--                        {{scope.row.status==3?formatDate(scope.row,'checkTime'):""}}-->
<!--                    </template>-->
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140" :formatter="formatDate" prop="effectTime" label="生效时间"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true" width="90" label="操作" fixed="right">
                    <template slot-scope="scope" >
                            <el-button size="mini" type="text"  v-if="scope.row.goodsParent!=0" @click="viewContract(scope.row)">
                                查看
                            </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

    <!-- 侧滑关闭必须有 -->
    <div class="ty-drawer-footer">
        <el-button type="primary" @click="cancel()">返回</el-button>
    </div>
</el-drawer>
</template>

<script>
export default {
    name: '',
    props: {
        orderId: String,
        name: String,
        visible: Boolean
    },
    data(){
        return {
            showVisisble:this.visible,
            serviceData: [], //表格数据
            statusArr:[
                {"id":0,"Text":"待签订"},
                {"id":1,"Text":"已签订"},
                {"id":2,"Text":"待审核"},
                {"id":3,"Text":"审核通过"},
                {"id":4,"Text":"驳回"},
                {"id":"5","Text":"协议异常"}],
            businessTypeIdArr:[
                {"id":1,"Text":"报名协议"},
                {"id":0,"Text":"保险协议"}
                ],
            contractTemplateTypeArr:[
                {"id":0,"Text":"线上"},
                {"id":1,"Text":"线下"}
                ]
        }
    },
    watch:{
        visible( newValue ){
            this.showVisisble = newValue
            if( newValue ){
               this.getDataList();
            }
        }
    },
    methods: {
            getDataList(orderId){
                if(orderId){
                    this.$fetch('findByOrderId',{ orderId }).then(response=>{
                    let result = response.data;
                    this.serviceData = result;
                    this.viewContract(this.serviceData[0])
                }).catch(error=>{
                    console.log(error);
                    parent.mVue.$message.error(`请求失败:${error.message}`);
                })
              }
            },

             cancel(){
                 this.$emit('update:visible', false)
            },

            formatDate(row, column){
                // 获取单元格数据
                let key = column.property;
                if(key==undefined){
                    key = column;
                }
                let data = row[key];
                if (!data) {
                    return '';
                }
                let dt = new Date(data);
                let dtYear = dt.getFullYear()
                let dtMonth = dt.getMonth()+1<10 ? '0'+(dt.getMonth()+1) : dt.getMonth()+1;
                let dtDate = dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate();
                let dtHours = dt.getHours()<10 ? '0'+dt.getHours() : dt.getHours();
                let dtMin = dt.getMinutes()<10 ? '0'+dt.getMinutes() : dt.getMinutes();
                return dtYear + '-' + dtMonth + '-' + dtDate + ' ' + dtHours + ':' + dtMin;
            },
            formatFormAllData(row, column){
                switch (column.property) {
                    case "businessTypeId": return this.formatDetailData(row[column.property],this.businessTypeIdArr);//业务类型
                    case "contractTemplateType": return this.formatDetailData(row[column.property],this.contractTemplateTypeArr);//协议类型
                    case "status":return this.formatDetailData(row[column.property],this.statusArr);//协议审核
                    default:return "";
                }
            },
            formatDetailData(data,list,Type){
                if(Type!=undefined){
                    if(data==undefined||data==""){
                        return "--";
                    }
                }
                for (let i = 0; i < list.length; i++) {
                    if(list[i].id==data){
                        return list[i]["Text"];
                    }
                }
                return data;
            },
            
            
            viewContract(row){
                if (row.platform == 1) {
                    // 上上签
                    this.$fetch( 'getPreviewNewUrl',{
                        id: row.id
                    } ).then(response => {
                      if(response.code == 200) {
                        window.open(response.data)
                      } else {
                        this.$message.error(response.msg)
                      }
                      
                    })
                    .catch(error => {
                        this.$message.error(`请求失败:${error.message}`);
                    })
                }

            }
    }
}
</script>

<style scoped lang="scss">
.iconxieyiguanli{
    color: #9bacbf;
    margin-right: 8px;
}

.ty-drawer-footer {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  text-align: center;
}

.ty-drawer-content {
  width: 94%;
  margin: 0 auto;
}
</style>