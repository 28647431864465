<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 14:25:33
 * @LastEditors: Seven
 * @LastEditTime: 2021-08-24 17:11:57

 服务详情
-->
<template>
<HqDrawer ref="drawer"  title="服务详情" :visible.sync="showVisible" size="90%">
    <div class="ty-drawer-content">
        <div class="ty-try-header">
            <p>
                <span>{{this.goodsName}} {{this.commodityName}}</span>
                <span></span>
            </p>
            <!-- <p></p> -->
        </div>
        <div class="ty-page-list ty-drawer-list">
            <el-table
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#e5f2ff' }"
                    style="width: 100%;margin:0 0 10px;"
                    highlight-current-row
                    :data="serviceData">
                <el-table-column :show-overflow-tooltip="true"  fixed prop="goodsId" label="商品ID"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="200" fixed prop="goodsName" label="单品名称"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140" prop="commodityName" label="班型"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="kuaidaTotalStr" label="会答总数"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140" prop="effectiveMonth" label="学习有效期(月)"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140" prop="trainEffectiveMonth" label="培训有效期(月)"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140" prop="retrainingCount" label="复训期(月)"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140"  prop="restudyCountStr" label="重读次数" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140"  prop="leaveCount" label="请假次数" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140"  prop="shiftClassCount" label="调班次数" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140"  prop="suspendSchoolCount" label="休学次数" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  width="140"  prop="shiftSchoolCount" label="转校次数" ></el-table-column>
            </el-table>
        </div>
    </div>
    <!-- 侧滑关闭必须有 -->
    <div slot="footer">
        <el-button type="primary" @click="cancel()">返回</el-button>
    </div>
</HqDrawer>
</template>

<script>
export default {
    name: "service-detail",
    data(){
        return {
           goodsName:'',
           commodityName:'',
           serviceData: [],
           showVisible: this.visible      
        }
    },

    props:{
        orderId: String,
        businessId: Number,
        visible: Boolean,
    },

    watch: {
        visible( newValue ){
            this.showVisible = this.visible;
            if( newValue ){
                this.getDataList();
            }
        }
    },
    methods: {
        init() {
          this.$refs.drawer.init()
        },
        cancel(){
            this.$emit("update:visible", false)
            this.$refs.drawer.close()
        },
        getDataList(){
            if(this.orderId){
            this.$fetch("findOrderGoodsServiceInfoByOrderId", {
                orderId: this.orderId
            }).then(response => {
                let result = response.data || {};
                let orderGoodsVo = result["orderGoodsVo"];
                this.goodsName = orderGoodsVo["goodsName"];
                this.commodityName = orderGoodsVo["commodityName"];
                this.serviceData = result["orderGoodsServiceInfos"] || [];
            }).catch(error => {
                console.log(error);
                this.$message.error(`请求失败:${error.message}`);
            })
        }
        }
    }
}
</script>

<style lang="scss" scoped>

#app {
    background: #ffffff;
}

.ty-drawer-content {
  width: 94%;
  margin: 0 auto;
}

.ty-drawer-footer {
  position: absolute;
  bottom: 24px;
  left: 0;
  text-align: center;
  width: 100%;
}

</style>