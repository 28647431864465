<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-08-06 11:32:16
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-08-06 16:44:19
-->
<template>
  <el-dialog
    :visible.sync="showVisible"
    :show-close="false"
    :close-on-press-escape="false"
    :append-to-body="true"
  >
    <el-form :label-width=label_width label-position="left" :class="{'ty-el-form':skin}">
        <el-form-item label="驳回原因">
            <el-input v-model="rejectReason" 	
                :rows="3"
                type="textarea"
                placeholder="协议驳回原因，60 字内"
                maxlength="60"
                show-word-limit
                :class="{'ty-textarea-h90':skin}" ></el-input>
                <span style="display: inline-block;font-size:12px" :style="{'color': skin?'#F54955':'#f54955'}" >注：系统会将驳回原因反馈到学员端，请注意措辞</span>
        </el-form-item>
    </el-form>
    <div style="text-align: center;" class="btn">
        <el-button type="primary" @click="confirmReject()">确定</el-button>
        <el-button @click="cancelReject()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
    name:'audit-reject',
    data(){
        return {
            skin: '', // 皮肤
            label_width: '80px',
            rejectReason:'',
            showVisible: this.visible
        }
    },
    props: {
        visible: Boolean
    },
    watch: {
        visible( newValue ){
            this.showVisible = newValue;
            if( newValue ){
                console.log(newValue)
            }
        }
    },
    mounted() {

		this.label_width = '80px'
    },
    methods: {
        confirmReject() {
            if (this.rejectReason.length < 1) {
                this.$message.error(`请输入驳回原因`);
                return;
            }
            this.$hqMessageBox({
                title: '提示',
                message: '确认驳回吗?'
            }).then( () =>{
                this.$emit('rejectOk', this.rejectReason)
                this.$emit("update:visible", false)
            });
        },
        cancelReject(){
            this.$emit("update:visible", false)
        },
    }
}
</script>

<style scoped lang="scss">
[v-cloak] {
    display: none;
}
#app{
    background: #ffffff;
}
.ty-el-form.el-form{
    padding: 0 18px 0 8px;
    /* margin-top: -10px; */
}
.el-form-item>.el-form-item__label:before{
    content: '*';
    color: #f54955;
    margin-right: 4px;
}
.btn .el-button{
    padding: 10px 27px;
}
.ty-textarea-h90 .el-textarea__inner{
    min-height: 90px !important;
}
.el-input__inner::-webkit-input-placeholder{
    color:#999999;
}
.el-input__inner:-moz-placeholder{
    color:#999999;
}
.el-input__inner::-moz-placeholder{
    color:#999999;
}
.el-input__inner:-ms-input-placeholder{
    color:#999999;
}
.el-textarea__inner::-webkit-input-placeholder{
    color:#999999;
}
.el-textarea__inner:-moz-placeholder{
    color:#999999;
}
.el-textarea__inner::-moz-placeholder{
    color:#999999;
}
.el-textarea__inner:-ms-input-placeholder{
    color:#999999;
}
</style>