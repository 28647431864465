<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 14:28:17
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-25 11:17:05

 单品的服务详情
-->
<template>
 <HqModal :visible="showVisible" width="500" @on-cancel="cancel">
    <ul class="ty-column-table">
        <li><span>类型</span><span>次数</span></li>
        <li><span>会答次数</span><span>{{serviceData.length>0 ? serviceData[0].kuaidaTotalStr : ''}}</span></li>
<!--        <li><span>会答频次</span><span>{{serviceData.length>0 ? serviceData[0].kuaidaFrequency : ''}}</span></li>-->
        <li v-if="isHasEffectiveDay == 1"><span>学习有效期（天）</span><span>{{serviceData.length>0 ? serviceData[0].effectiveTemp : ''}}</span></li>
        <template v-if="isHasEffectiveDay == 0">
          <li><span>学习有效期（月）</span><span>{{serviceData.length>0 ? serviceData[0].effectiveMonth : ''}}</span></li>
          <li><span>培训有效期（月）</span><span>{{serviceData.length>0 ? serviceData[0].trainEffectiveMonth : ''}}</span></li>
          <li><span>复训期（月）</span><span>{{serviceData.length>0 ? serviceData[0].retrainingCount : ''}}</span></li>
          <li v-if = "businessId +'' !== '0' "><span>重读次数</span><span>{{serviceData.length>0 ? serviceData[0].restudyCountStr : ''}}</span></li>
          <li v-if = "businessId +'' !== '0' "><span>请假次数</span><span>{{serviceData.length>0 ? serviceData[0].leaveCount : ''}}</span></li>
          <li v-if = "businessId +'' !== '0' "><span>调班次数</span><span>{{serviceData.length>0 ? serviceData[0].shiftClassCount : ''}}</span></li>
          <li><span>休学次数</span><span>{{serviceData.length>0 ? serviceData[0].suspendSchoolCount : ''}}</span></li>
          <li><span>转校次数</span><span>{{serviceData.length>0 ? serviceData[0].shiftSchoolCount : ''}}</span></li>
        </template>
    </ul>
 </HqModal>
</template>

<script>
export default {
    name: "single-service-detail",
    props: {
        orderId: String,
        businessId: Number,
        visible: Boolean,
        isHasEffectiveDay: [String, Number]
    },
    watch: {
        visible( newValue ){
            this.showVisible = this.visible;
            if( newValue ){
              this.getDataList();
            }
        }
    },
    data(){
        return {
            showVisible: false,
            serviceData: [],
        }
    },
    mounted(){
    },
    methods: {
        cancel(){
            this.$emit("update:visible", false)
        },

        getDataList(){
            if(this.orderId){
                this.$fetch("findOrderGoodsServiceInfoByOrderId", {
                    orderId: this.orderId
                }).then(response => {
                    let result = response.data;
                    this.serviceData = result["orderGoodsServiceInfos"];
                }).catch(error => {
                    console.log(error);
                    this.$message.error(`请求失败:${error.message}`);
                })
            }
        }


    }
}
</script>

<style lang="scss" scoped>
    [v-cloak] {
            display: none;
        }
        #app{
            background: #ffffff;
        }
        /* 两列表格弹窗 */
        .ty-column-table li{
            display: flex;
            height: 42px;
            line-height: 42px;
        }
        .ty-column-table li:nth-child(odd){
            background: #f7fcfc;
        }
        .ty-column-table li:first-child{
            background: #e5f2ff;
            border-radius: 4px 4px 0px 0px;
            color: #333333;
            font-size: 12px;
            font-weight: 700;
        }
        .ty-column-table li:last-child{
            border-radius: 0px 0px 4px 4px;
        }
        .ty-column-table li span{
            flex: 1;
            text-align: center;
        }
        .ty-column-table li:first-child span:first-child{
            border-right: 1px solid #e1e4eb;
        }
        .ty-column-table li + li span{
            border-left: 1px solid #e1e4eb;
            border-bottom: 1px solid #e1e4eb;
            color: #999999;
            font-size: 13px;
        }
        .ty-column-table li + li span:nth-child(2){
            border-right: 1px solid #e1e4eb;
            font-size: 13px;
            color: #666666;
        }
</style>>
