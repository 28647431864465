<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 15:47:30
 * @LastEditors: Seven
 * @LastEditTime: 2022-06-10 16:03:05
-->
<template>
<HqDrawer ref="drawer"  title="收款明细" :showHeader="false"  :visible.sync="showVisisble" size="90%">
    <div class="header">
        <h3 :class="navIndex==0?'selected': ''"  @click="toNav(0)">收款明细</h3>
        <h3 :class="navIndex==1?'selected': ''" @click="toNav(1)">优惠明细</h3>
    </div>
    <div class="ty-drawer-content">
        <template v-if="navIndex == 0">
                    <div class="ty-try-header">
            <p>
                <span><i class="iconfont iconxieyiguanli"></i>{{orderGoodsVo.goodsName}}</span>
                <span>{{orderGoodsVo.commodityName}}</span>
                <span class="fr">应收金额:<i style="margin-right:24px;color: red" class="red1">￥ {{orderGoodsVo.payPrice}}</i>已收金额:<i class="red1" style="color: red">￥ {{orderGoodsVo.amountReceivedAll}}</i></span>
            </p>

        </div>
        <div v-for="(data,index) in tableData" :key="index" style="margin-top: 10px; margin-bottom: 20px; padding: 0 30px 0 0;">
            <el-table
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#e5f2ff' }"
                    style="width: 100%;margin:10px 0;"
                    max-height='700px'
                    :data="data.paySummaryList">
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].countNumber != undefined" width="140" prop="countNumber" label="支付笔数" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].payTime != undefined" :formatter="formatDate" width="140" prop="payTime" label="支付时间" ></el-table-column>
                <!--                // 新增报名_贷款-首付（在线支付）-支付明细-->
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].loanStage != undefined" :formatter="formatFormAllData" width="140" prop="loanStage" label="支付阶段" ></el-table-column>

                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].downPayment != undefined" width="140" :formatter="numberFormatter" prop="downPayment" label="首付款" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].loanAmount != undefined" width="140"  :formatter="numberFormatter" prop="loanAmount" label="贷款金额" ></el-table-column>

                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].type == 2" :formatter="numberFormatter" width="140" prop="paymentAmount" label="支付金额" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].installmentStatus != undefined" :formatter="formatFormAllData" width="140" prop="installmentStatus" label="贷款状态" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].installmentNumber != undefined" min-width="100" prop="installmentNumber" label="贷款期数" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].discountRate != undefined" min-width="100" prop="discountRate" label="贴息率" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].discountAmount != undefined" min-width="100" prop="discountAmount" label="贴息" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].accountAmount != undefined" min-width="100" prop="accountAmount" label="到账金额" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true" v-if="data.paySummaryList[0].test7 != undefined" min-width="100" prop="test7" label="降贷"></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].loanReceiptNo != undefined" min-width="100" prop="loanReceiptNo" label="借据号" ></el-table-column>

                <!--                // 升班报名-平升_余额抵扣-支付明细-->
<!--                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].upClassDiscount !== '0' " min-width="100" :formatter="numberFormatter" prop="upClassDiscount" label="升班优惠" ></el-table-column>-->

                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].changeClassDiscount !== '0' " min-width="100" :formatter="numberFormatter" prop="changeClassDiscount" label="转班抵扣" ></el-table-column>

                <!--                // 新增报名_贷款-首付（余额抵扣）-支付明细-->
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].deduction !== '0' " min-width="100" :formatter="numberFormatter" prop="deduction" label="余额抵扣" ></el-table-column>
<!--                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].deductionUserMobile != undefined" min-width="100" prop="deductionUserMobile" label="余额抵扣学员账号" ></el-table-column>-->

                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].payType != undefined" :formatter="formatFormAllData" width="140" prop="payType" label="支付方式" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].thirdName != undefined" width="140" prop="thirdName" label="第三方" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].thirdOrderNo != undefined" width="140" prop="thirdOrderNo" label="第三方订单号" ></el-table-column>

                <!--                // 新增报名_在线支付-支付明细-->
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].tradeNo != undefined" width="270" prop="tradeNo" label="商户订单号" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].tradeSerialNumber != undefined" width="250" prop="tradeSerialNumber" label="交易号" ></el-table-column>

                <!--                // 新增报名_余额抵扣+在线支付-支付明细-->
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].deductionUserMobile != undefined" width="140" prop="deductionUserMobile" label="学员账号" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].deductionUserName != undefined" width="140" prop="deductionUserName" label="学员姓名" ></el-table-column>


<!--                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].paymentAmount != undefined" min-width="100" :formatter="numberFormatter" prop="paymentAmount" label="实收金额" ></el-table-column>-->
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].amountToBeReceived != undefined" min-width="100" :formatter="numberFormatter" prop="amountToBeReceived" label="待收金额" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].downPaymentAllPay != undefined" :formatter="formatFormAllData" min-width="100" prop="downPaymentAllPay" label="首付收齐款" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  v-if="data.paySummaryList[0].allPay != undefined" :formatter="formatFormAllData" min-width="100" prop="allPay" label="收齐款" label-class-name="ty-ml8" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true" min-width="100" prop="allPay" label="凭证" fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text" v-if="scope.row.photoUrl" @click="seeClick(scope.row)">查看</el-button>
                  </template>
                </el-table-column>

            </el-table>

            <el-table
                    style="width: 100%;margin:30px 0;"
                    max-height="500"
                    :data="data.orderInOutProjectDtoList"
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#F5F7FB' }"
            >
                <el-table-column :show-overflow-tooltip="true"  fixed prop="inoutProjectCode" label="收支项目编码" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  fixed prop="inoutProjectName" label="收支项目名称" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="inoutProportionStr" label="收支占比" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="chargeOrder" label="收费顺序" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="standardPrice" label="标准价格" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="goodsDiscount" label="优惠额" width="140" >
                    <div slot-scope="scope">
                        {{scope.row.goodsDiscount }}
                    </div>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="receivable" label="应收金额" width="140" ></el-table-column>
                <!-- 嘉仁说伟伟的需求隐藏这两个字段 -->
                <!-- <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="changeClassDiscount" label="转班抵扣额" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="deduction" label="余额抵扣额" width="140" ></el-table-column> -->
                <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="settleAccounts" label="本次实收金额" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="settledAmount" label="累计已收金额" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="discountPercentageStr" label="优惠百分比" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="performanceRatioStr" label="绩效比例" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="completionRatioStr" label="完成率比例" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="inoutProjectType" label="收支项目类型" min-width="120" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="calculatePerformance" label="是否计算绩效" min-width="120" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="incomeMethod" label="计算收入方式" min-width="150" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="monthlyApportionmentOrNot" label="是否按月分摊" min-width="120" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="incomeApportionmentCycle" label="收入分摊周期(月)" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="startApportionmentCycle" label="开始分摊周期(月)" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="apportionmentRatioStr" label="分摊比例" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="countyApportionmentRatioStr" label="自营三分成比例" width="140" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="selfSupportRatioStr" label="自营二分成比例" width="140" label-class-name="ty-ml8" ></el-table-column>
                <!-- <el-table-column :show-overflow-tooltip="true"  min-width="100" prop="allPay" label="凭证" fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text" @click="seeClick(scope.row)">查看</el-button>
                  </template>
                </el-table-column> -->

            </el-table>
            <div style="margin-top: 10px"></div>
            <el-image ref="previewImg" :src="url" :preview-src-list=[url] v-show="false">
            </el-image>
        </div>
        </template>
        <template v-if="navIndex == 1">
             <!-- v-model="transactionInfo[item.value]" -->
             <el-row>
                 <el-col :span="4">
                     <v-text-field
                     v-model="discountAmount"
                        width="400px"
                        label="优惠总金额"
                        hide-details="auto"
                        outlined
                        readonly
                        :dense="true"
                        :height="32"
                        ></v-text-field>
                 </el-col>
             </el-row>
            
             <template v-if="!discountList || discountList.length==0">
                <el-table
                    style="width: 100%;margin:30px 0;"
                    max-height="500"
                    :data="[{}]"
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#e5f2ff' }"
                >
                <el-table-column :show-overflow-tooltip="true"  label="优惠笔数" min-width="150"> </el-table-column> 
                <el-table-column :show-overflow-tooltip="true"  prop="discount" label="优惠金额" min-width="220" > </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="type" label="使用时间" min-width="140" >
             
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="type" label="优惠类型" min-width="140" >
             
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="xxxxx" label="状态" min-width="140" >
                  
                </el-table-column>
                <el-table-column  :show-overflow-tooltip="true"  prop="orderId" label="售后单号" min-width="240" ></el-table-column>
              
            </el-table>
              </template> 
                <template  v-else v-for="(item, index) in discountList">
                    <el-table
                    :key="index"
                    style="width: 100%;margin:30px 0;"
                    max-height="500"
                    :data="[item]"
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#e5f2ff' }"
                >
                <el-table-column :show-overflow-tooltip="true"  label="优惠笔数" min-width="150">第 {{index+1}} 笔</el-table-column> 
                <el-table-column :show-overflow-tooltip="true"  prop="discount" label="优惠金额" min-width="120" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="type" label="使用时间" min-width="240" >
                    <template slot-scope="scope">
                        {{scope.row.useTime | formatDate('YYYY-MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="type" label="优惠类型" min-width="140" >
                    <template slot-scope="scope">
                        {{ scope.row.type == 1 ? '升班优惠': scope.row.type == 0? '活动优惠':'第三方订单优惠'  }}
                    </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="xxxxx" label="状态" min-width="140" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.status==0" style="color:red">未使用</div>
                        <div v-if="scope.row.status ==1" style="color:green">已使用</div>
                        <div v-if="scope.row.status == 2">已过期</div>
                    </template>
                </el-table-column>
                <el-table-column  v-if="item.type===1" :show-overflow-tooltip="true"  prop="afterSaleId" label="售后单号" min-width="240" ></el-table-column>
                <el-table-column  v-if="item.type===0" :show-overflow-tooltip="true"  prop="activityId" label="活动ID" min-width="140" ></el-table-column>
                <el-table-column  v-if="item.type===0" :show-overflow-tooltip="true"  prop="activityName" label="活动名称" min-width="240" ></el-table-column>
                <el-table-column  v-if="item.type===0" :show-overflow-tooltip="true"  prop="activityEndTime" label="活动时间" min-width="240" >
                    <template slot-scope="scope">
                        <div>
                            <div>开始: {{scope.row.activityStartTime | formatDate2('YYYY-MM-DD HH:mm:ss')}}</div>
                            <div>结束: {{scope.row.activityEndTime | formatDate2('YYYY-MM-DD HH:mm:ss')}}</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
                </template>
            
        </template>
    </div>
    <!-- 侧滑关闭必须有 -->
    <div slot="footer">
        <el-button type="primary" @click="cancel()">确定</el-button>
    </div>
</HqDrawer>
</template>

<script>
export default {
    name: "collect-detail-single-modal",
    data(){
        return {
            navIndex: 0,
            discountAmount: 0.00,
            discountList:[],
            payTypeArr:[{"id":1,"Text":"支付宝"},{"id":2,"Text":"微信支付"},{"id":3,"Text":"有钱花"},{"id":4,"Text":"分付君"},{"id":5,"Text":"学币支付"},{"id":6,"Text":"学员余额"}],
            deductionArr:[{"id":0,"Text":"否"},{"id":1,"Text":"是"}],
            installmentStatusArr:[{"id":6,"Text":"贷款中"},{"id":7,"Text":"贷款完成"},{"id":8,"Text":"贷款失败"}],
            loanStageArr:[{"id":1,"Text":"首付款"},{"id":2,"Text":"贷款"}],
            name: '', //商品名
            CommodityName: '', //规格名
            money: 1000,
            amountReceivable:'',
            amountReceived:'',
            serviceData: [], //表格数据
            service: [],
            tableData: [],
            orderGoodsVo:{},
            goodsTemplate:null,
            showVisisble: this.visible,
            url: '',
        }
    },
    props: {
        orderId: String,
        visible: Boolean,
        payTypeList: Array,
    },
    watch: {
        visible( newValue ){
            this.showVisisble = this.visible;
            if( newValue ){
                this.getDataList();

                this.getDiscountList(this.orderId);
                // this.getDiscountList(this.orderId);
            }
        }
    },

     methods: {

       //获取商品优惠  
       async getDiscountList( orderId ){
           let res = await this.$fetch( 'getDiscountList', {orderId} );
           if( res.code == 200 ){
               let data = res.data || [];
               this.discountList = data;
               this.discountAmount = this.discountList.reduce( (first, last)=>{
                 console.log(first, last, "**********************");
                   return first + last.discount
               }, 0 )
               if(!this.discountAmount){
                   this.discountAmount = 0;
               }
           } 
       
       },  

       init() {
              this.$refs.drawer.init()
            },

            // 查看凭证
            seeClick(row) {
              console.log(row);
              this.url = row.photoUrl
              this.$refs.previewImg[0].showViewer = true
            },
            cancel() {
                this.$emit('update:visible', false)
                this.$refs.drawer.close()
            },
            getDataList() {
                // alert(this.orderId)
                this.$fetch('queryCollectDetailPageByOrderId', {
                    orderId: this.orderId
                }).then(response => {
                    const result = response.data || {};
                    this.orderGoodsVo = result["orderGoodsVo"] || {};
                    let tempArr = result["paySummaryAndInoutProjects"] || [];
                    for (let i = 0; i <tempArr.length ; i++) {
                        let doc = tempArr[i];
                        let paySummaryList = doc["paySummaryList"];
                        paySummaryList[0].deduction = paySummaryList[0].deduction + "";
                        paySummaryList[0].upClassDiscount = paySummaryList[0].upClassDiscount + "";
                        paySummaryList[0].changeClassDiscount = paySummaryList[0].changeClassDiscount + "";
                        paySummaryList[0].countNumber = "第"+paySummaryList[0].countNumber+"笔"
                    }
                    this.tableData = tempArr;
                }).catch(error => {
                    console.log(error);
                    parent.mVue.$message.error(`请求失败:${error.message}`);
                })
            },
            formatDate(row, column){
                // 获取单元格数据
                let data = row[column.property];
                if (!data) {
                    return '--';
                }
                let dt = new Date(data);
                let dtYear = dt.getFullYear()
                let dtMonth = dt.getMonth()+1<10 ? '0'+(dt.getMonth()+1) : dt.getMonth()+1;
                let dtDate = dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate();
                let dtHours = dt.getHours()<10 ? '0'+dt.getHours() : dt.getHours();
                let dtMin = dt.getMinutes()<10 ? '0'+dt.getMinutes() : dt.getMinutes();
                return dtYear + '-' + dtMonth + '-' + dtDate + ' ' + dtHours + ':' + dtMin;
            },
            formatFormAllData(row, column){
                switch (column.property) {
                    case "installmentStatus": return this.formatDetailData(row[column.property],this.installmentStatusArr);//贷款状态
                    case "allPay": return this.formatDetailData(row[column.property],this.deductionArr);//收齐款
                    case "downPaymentAllPay": return this.formatDetailData(row[column.property],this.deductionArr);//首付收齐款
                    case "payType":return this.formatDetailData(row[column.property],this.payTypeList);//支付方式
                    case "loanStage":return this.formatDetailData(row[column.property],this.loanStageArr);//支付阶段
                    case "deduction":return this.formatDetailData(row[column.property],this.deductionArr,1);// 余额
                    default:return "";
                }
            },
            formatDetailData(data,list,Type){
                for (let i = 0; i < list.length; i++) {
                    if(list[i].id===data){
                        return list[i]["Text"];
                    }
                }
                if(Type!=undefined){
                    if(data==undefined||data==""){
                        return "--";
                    }
                }
                return data;
            },
            numberFormatter(row, column) {
                let data = row[column.property];
                if (data === undefined || data === "") {
                    return "0.00";
                }
                data = Number(data);
                return data.toFixed(2);
            },

            toNav( index ){
                this.navIndex = index;
            }
        },
        mounted() {
            // this.getDataList();

        },

}
</script>

<style scoped  lang="scss">

.iconxieyiguanli {
    color: #9bacbf;
    margin-right: 8px;
}
.ty-drawer-content {
    width: 94%;
    margin: 0 auto;
    padding-bottom: 80px;
  }
  .ty-drawer-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 60px;
    text-align: center;
    width: 100%;
    background: #fff;
    z-index: 999;
  }

  .header {
      height: 60px;
      display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
    // border-bottom: 1px solid #eee;
    box-shadow: 1px 1px 1px #ddd;
    h3 {
        width: 120px;
        text-align: center;
        color: #777;
        cursor: pointer;
    }

    h3.selected {
        font-weight: bold;
        color: #000;
    }
  }
</style>