var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HqDrawer",
    {
      ref: "drawer",
      attrs: { title: "服务详情", visible: _vm.showVisible, size: "90%" },
      on: {
        "update:visible": function($event) {
          _vm.showVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "ty-drawer-content" }, [
        _c("div", { staticClass: "ty-try-header" }, [
          _c("p", [
            _c("span", [
              _vm._v(_vm._s(this.goodsName) + " " + _vm._s(this.commodityName))
            ]),
            _c("span")
          ])
        ]),
        _c(
          "div",
          { staticClass: "ty-page-list ty-drawer-list" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", margin: "0 0 10px" },
                attrs: {
                  border: "",
                  stripe: "",
                  size: "mini",
                  "element-loading-text": "拼命加载中",
                  "header-cell-style": { background: "#e5f2ff" },
                  "highlight-current-row": "",
                  data: _vm.serviceData
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    fixed: "",
                    prop: "goodsId",
                    label: "商品ID"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "200",
                    fixed: "",
                    prop: "goodsName",
                    label: "单品名称"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "commodityName",
                    label: "班型"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    prop: "kuaidaTotalStr",
                    label: "会答总数"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "effectiveMonth",
                    label: "学习有效期(月)"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "trainEffectiveMonth",
                    label: "培训有效期(月)"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "retrainingCount",
                    label: "复训期(月)"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "restudyCountStr",
                    label: "重读次数"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "leaveCount",
                    label: "请假次数"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "shiftClassCount",
                    label: "调班次数"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "suspendSchoolCount",
                    label: "休学次数"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    width: "140",
                    prop: "shiftSchoolCount",
                    label: "转校次数"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }