var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HqDrawer",
    {
      ref: "drawer",
      attrs: { title: "商品详情", visible: _vm.showVisible, size: "90%" },
      on: {
        "update:visible": function($event) {
          _vm.showVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "ty-drawer-content" }, [
        _c("div", { staticClass: "ty-try-header" }, [
          _c("p", [
            _c("span", [
              _c("i", { staticClass: "iconfont iconxieyiguanli" }),
              _vm._v(_vm._s(this.name) + " " + _vm._s(this.commodityName))
            ]),
            _c("span")
          ])
        ]),
        _c(
          "div",
          { staticClass: "ty-page-list ty-drawer-list" },
          [
            _c(
              "el-table",
              {
                staticStyle: {
                  width: "100%",
                  margin: "0 0 10px",
                  border: "1px solid #E1E4EB"
                },
                attrs: {
                  border: "",
                  stripe: "",
                  size: "mini",
                  "element-loading-text": "拼命加载中",
                  "header-cell-style": { background: "#e5f2ff" },
                  "highlight-current-row": "",
                  "max-height": "700px",
                  data: _vm.contentList,
                  "default-expand-all": "",
                  "row-key": "teacherContentId",
                  "tree-props": { children: "children" }
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    fixed: "",
                    prop: "teacherContentId",
                    label: "id",
                    type: "",
                    width: "150"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    fixed: "",
                    prop: "name",
                    label: "商品内容",
                    "min-width": "280",
                    "class-name": "ellipsis2 tree"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    prop: "typeStr",
                    label: "类型",
                    "min-width": "150"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    prop: "classTimesStr",
                    label: "课次数量",
                    "min-width": "150"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    formatter: _vm.formatFormAllData,
                    prop: "statusOrder",
                    label: "内容权限",
                    "min-width": "150"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    formatter: _vm.formatFormAllData,
                    prop: "teachingSchedule",
                    label: "排课"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    prop: "dueTimeStr",
                    label: "有效期",
                    width: "170",
                    "label-class-name": "ty-ml8"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }