<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 20:28:53
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-28 17:14:39
-->
<template>
<el-drawer  title="还款明细" :visible.sync="showVisisble" size="90%">
    <div class="ty-drawer-content">
        <!-- <div class="ty-page-list ty-drawer-list "> -->
            <div class="ty-page-elform-section">
                <div class="ty-info_title">
                    <p class="flag-name">还款详情</p>
                </div>
                <div class="ty-content">
                   
                    <!-- <ul style="display: inline-block">
                        <li class="liClass">
                            <span>报名单号：</span>
                            <span>{{this.data.orderNo}}</span>
                        </li>
                        <li class="liClass">
                            <span>商品名称：</span>
                            <span>{{this.data.goodsName}}</span>
                        </li>
                        <li class="liClass">
                            <span>学员姓名：</span>
                            <span>{{this.data.userName}}</span>
                        </li>
                        <li class="liClass">
                            <span>支付方式：</span>
                            <span>{{this.data.payType}}</span>
                        </li>

                        <li class="liClass">
                            <span>贷款平台状态：</span>
                            <span>{{this.data.loanStatus}}</span>
                        </li>
                        <li class="liClass">
                            <span>贷款分期期数：</span>
                            <span>{{this.data.installmentNumber}}</span>
                        </li>
                        <li class="liClass">
                            <span>还款期数：</span>
                            <span>{{this.data.repaymentNumber}}</span>
                        </li>
                        <li class="liClass">
                            <span>逾期天数：</span>
                            <span>{{this.data.overdueNumber}}</span>
                        </li>

                        <li class="liClass">
                            <span>贷款借据号：</span>
                            <span>{{this.data.tradeSerialNumber}}</span>
                        </li>
                        <li class="liClass">
                            <span>降贷：</span>
                            <span>{{this.data.loanReduction}}</span>
                        </li>
                        <li class="liClass">
                            <span>降贷记录：</span>
                            <span>{{this.data.loanReductionRecord}}</span>
                        </li>
                    </ul> -->

                    <el-row :gutter="24">
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.orderNo"
                          label="报名单号"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.goodsName"
                          label="商品名称"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.userName"
                          label="学员姓名"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.payType"
                          label="支付方式"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.loanStatus"
                          label="贷款平台状态"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.installmentNumber"
                          label="贷款分期期数"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.repaymentNumber"
                          label="还款期数"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.overdueNumber"
                          label="逾期天数"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.tradeSerialNumber"
                          label="贷款借据号"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.loanReduction"
                          label="降贷"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="data.loanReductionRecord"
                          label="降贷记录"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                    </el-row>
                    
                    
                    <!-- <ul style="display: inline-block; ">
                        <li class="liClass">
                            <span>贷款平台状态：</span>
                            <span>{{this.data.loanStatus}}</span>
                        </li>
                        <li class="liClass">
                            <span>贷款分期期数：</span>
                            <span>{{this.data.installmentNumber}}</span>
                        </li>
                        <li class="liClass">
                            <span>还款期数：</span>
                            <span>{{this.data.repaymentNumber}}</span>
                        </li>
                        <li class="liClass">
                            <span>逾期天数：</span>
                            <span>{{this.data.overdueNumber}}</span>
                        </li>
                    </ul> -->
                
                    <!-- <ul style="display: inline-block; ">
                        <li class="liClass">
                            <span>贷款借据号：</span>
                            <span>{{this.data.tradeSerialNumber}}</span>
                        </li>
                        <li class="liClass">
                            <span>降贷：</span>
                            <span>{{this.data.loanReduction}}</span>
                        </li>
                        <li class="liClass">
                            <span>降贷记录：</span>
                            <span>{{this.data.loanReductionRecord}}</span>
                        </li>
                    </ul> -->
                    
                </div>
            </div>
            <p class="h12bgF3F6FB"></p>
            <div class="ty-page-elform-section">
                <div class="ty-info_title">
                    <p class="flag-name">本期应还</p>
                </div>
                <div class="ty-content">
                    <!-- <ul style="display: inline-block; ">
                        <li class="liClass">
                            <span>总金额：</span>
                            <span>{{this.currentPeriod.totalMoney}}</span>
                        </li>
                        <li class="liClass">
                            <span>利息：</span>
                            <span>{{this.currentPeriod.intamt}}</span>
                        </li>
                        <li class="liClass">
                            <span>罚息：</span>
                            <span>{{this.currentPeriod.penaltyamt}}</span>
                        </li>
                    </ul> -->
                    <el-row :gutter="24">
                      <el-col :span="6">
                        <v-text-field
                          v-model="currentPeriod.totalMoney"
                          label="总金额"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="currentPeriod.intamt"
                          label="利息"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                      <el-col :span="6">
                        <v-text-field
                          v-model="currentPeriod.penaltyamt"
                          label="罚息"
                          hide-details="auto"
                          readonly
                          outlined
                          :dense="true"
                          :height="32"
                        ></v-text-field>
                      </el-col>
                    </el-row>
                </div>
            </div>
            <p class="h12bgF3F6FB"></p>
            <div class="ty-page-elform-section">
                <!-- <div class="ty-page-list ty-drawer-list"> -->
                    <div v-loading="loading"
                         element-loading-text="正在读取数据">
                        <el-table
                                border
                                stripe
                                size="mini"
                                element-loading-text="拼命加载中"
                                :row-style="{ height: '20px' }"
                                :header-cell-style="{ background: '#e5f2ff' }"
                                ref="multipleTable"
                                style="width: 100%;margin:0 0 10px;"
                                highlight-current-row
                                :cell-style="finalCellStyle"
                                :data="tableData"
                                @selection-change="handleSelectionChange"
                                @sort-change="handleSortChange">
                            <el-table-column :show-overflow-tooltip="true"  prop="num" label="序号" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="dueDate" label="到期还款日" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="totalMoney" label="总金额" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="principalPaidTotalMoney" label="已还总金额" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="planInstamt" label="应还本金" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="principalPaid" label="应还利息" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="penaltyamt" label="罚息" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="installmentStatus" label="贷款平台状态" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="paidTime" label="已还款日期" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="overdueNumber" label="逾期天数" width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="tradeSerialNumber" label="贷款借据号" width="190" label-class-name="ty-ml8" ></el-table-column>
                        </el-table>
                        <el-row type="flex" class="row-bg ty-page-fix" justify="center" style="height:50px;margin-top:18px;">
                            <el-pagination
                                    background
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :page-sizes="[10, 20, 30, 50, 100]"
                                    :page-size="pageInfo.pageSize"
                                    :current-page="pageInfo.curPage"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="pageInfo.total">
                            </el-pagination>
                        </el-row>
                    <!-- </div> -->
                </div>
            </div>
        <!-- </div> -->
    </div>
    <!-- 侧滑关闭必须有 -->
    <div class="ty-drawer-footer">
        <el-button type="primary" @click="cancel()">返回</el-button>
    </div>
</el-drawer>
</template>

<script>
export default {
    name: 'repayment-detail-modal',
    data(){
        return {
             isPackup: true, //是否显示‘展开’字
                pageInfo: {
                    pageSize: 10,
                    curPage: 1,
                    total: 0
                },
                loading: false,
                queryForm: {

                },
                currentPeriod:{},
                data:{},
                tableData: [],
                multipleTable: [],
                showVisisble: this.visible
        }
    },
    props: {
        orderId:String,
        visible: Boolean
    },
    watch: {
        visible( newValue ){
            this.showVisisble = newValue
            if( newValue  ){
                this.bindData(1);
            }
        }
    },
    methods: {
        cancel(){
                this.$emit('update:visible', false);
        },
        finalCellStyle({row, column, rowIndex, columnIndex}) {
            // console.log(row.statusPrice,column.property,column.label)
            if (row.state == 3 && column.property == 'state' && column.label == '支付状态') { // 支付成功
                return {
                    color: '#337AFF'
                }
            }
        },
        //修改每页记录大小
        handleSizeChange(pageSize) {
            this.pageInfo.pageSize = pageSize;
            this.bindData(1);
        },
        //换页
        handleCurrentChange(curPage) {
            this.pageInfo.curPage = curPage;
            this.bindData(curPage);
        },
        //选择
        handleSelectionChange(mulSelection) {
            this.multipleSelection = mulSelection;
        },
        //排序
        handleSortChange(sort) {
            this.orderBy = sort.prop;
            if (sort.order == 'descending') {
                this.orderBy += ' desc';
            }
            this.bindData(1);
        },
        bindData() {
            if (this.orderId) {
                this.tableData = [];
                this.$fetch('third_getDetailsByOrderId', {
                    orderId: this.orderId,
                    curPage:this.pageInfo.curPage,
                    pageSize:this.pageInfo.pageSize
                }).then(response => {
                        const result = response.data;
                        this.data = result || {};
                        let repaymentRecordPage = this.data["repaymentRecordPage"]||{};
                        this.currentPeriod = this.data["currentPeriod"]||{};
                        this.tableData = repaymentRecordPage["records"]||[];
                        this.pageInfo.total = repaymentRecordPage.total;
                        this.pageInfo.pageSize = repaymentRecordPage.size;
                        this.pageInfo.curPage = repaymentRecordPage.current;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$message.error(`请求失败:${error.message}`);
                    })
            }
        }
    }
}
</script>

<style  scoped lang='scss'>
 .liClass {
        font-size: 14px;
        display: inline-block;
        min-width: 320px;
        height: 36px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    .liClass span:nth-child(1){
        color: #999999;
    }
    .liClass span:nth-child(2){
        color: #333333;
    }
    .ty-page-elform-section {
        // padding: 30px 54px 36px 48px;
        width: 94%;
        margin: 0 auto;
        background: #ffffff;
        padding-bottom: 10px;
    }
    .ty-info_title {
        padding: 0;
        margin: 0;
        height: 50px;
        font-size: 15px;
        font-weight: 700;
        color: #333333;
    }
    .ty-content{
        // margin-left: 24px;
    }
    .h12bgF3F6FB{
        height: 12px;
        background: #F3F6FB;
    }
    .ty-drawer-content {
      width: 94%;
      margin: 0 auto;
      padding-bottom: 80px;
    }
    .ty-drawer-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 60px;
      text-align: center;
      width: 100%;
      background: #fff;
      z-index: 999;
    }
    
    .el-col-6 {
      margin-bottom: 16px;
    }
</style>