var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HqModal",
    {
      attrs: { visible: _vm.showVisible, width: "500" },
      on: { "on-cancel": _vm.cancel }
    },
    [
      _c(
        "ul",
        { staticClass: "ty-column-table" },
        [
          _c("li", [
            _c("span", [_vm._v("类型")]),
            _c("span", [_vm._v("次数")])
          ]),
          _c("li", [
            _c("span", [_vm._v("会答次数")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.serviceData.length > 0
                    ? _vm.serviceData[0].kuaidaTotalStr
                    : ""
                )
              )
            ])
          ]),
          _vm.isHasEffectiveDay == 1
            ? _c("li", [
                _c("span", [_vm._v("学习有效期（天）")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.serviceData.length > 0
                        ? _vm.serviceData[0].effectiveTemp
                        : ""
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.isHasEffectiveDay == 0
            ? [
                _c("li", [
                  _c("span", [_vm._v("学习有效期（月）")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.serviceData.length > 0
                          ? _vm.serviceData[0].effectiveMonth
                          : ""
                      )
                    )
                  ])
                ]),
                _c("li", [
                  _c("span", [_vm._v("培训有效期（月）")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.serviceData.length > 0
                          ? _vm.serviceData[0].trainEffectiveMonth
                          : ""
                      )
                    )
                  ])
                ]),
                _c("li", [
                  _c("span", [_vm._v("复训期（月）")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.serviceData.length > 0
                          ? _vm.serviceData[0].retrainingCount
                          : ""
                      )
                    )
                  ])
                ]),
                _vm.businessId + "" !== "0"
                  ? _c("li", [
                      _c("span", [_vm._v("重读次数")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceData.length > 0
                              ? _vm.serviceData[0].restudyCountStr
                              : ""
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.businessId + "" !== "0"
                  ? _c("li", [
                      _c("span", [_vm._v("请假次数")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceData.length > 0
                              ? _vm.serviceData[0].leaveCount
                              : ""
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.businessId + "" !== "0"
                  ? _c("li", [
                      _c("span", [_vm._v("调班次数")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceData.length > 0
                              ? _vm.serviceData[0].shiftClassCount
                              : ""
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("li", [
                  _c("span", [_vm._v("休学次数")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.serviceData.length > 0
                          ? _vm.serviceData[0].suspendSchoolCount
                          : ""
                      )
                    )
                  ])
                ]),
                _c("li", [
                  _c("span", [_vm._v("转校次数")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.serviceData.length > 0
                          ? _vm.serviceData[0].shiftSchoolCount
                          : ""
                      )
                    )
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }