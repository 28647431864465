var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showVisible,
        "show-close": false,
        "close-on-press-escape": false,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          class: { "ty-el-form": _vm.skin },
          attrs: { "label-width": _vm.label_width, "label-position": "left" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "驳回原因" } },
            [
              _c("el-input", {
                class: { "ty-textarea-h90": _vm.skin },
                attrs: {
                  rows: 3,
                  type: "textarea",
                  placeholder: "协议驳回原因，60 字内",
                  maxlength: "60",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.rejectReason,
                  callback: function($$v) {
                    _vm.rejectReason = $$v
                  },
                  expression: "rejectReason"
                }
              }),
              _c(
                "span",
                {
                  staticStyle: { display: "inline-block", "font-size": "12px" },
                  style: { color: _vm.skin ? "#F54955" : "#f54955" }
                },
                [_vm._v("注：系统会将驳回原因反馈到学员端，请注意措辞")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn", staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confirmReject()
                }
              }
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.cancelReject()
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }