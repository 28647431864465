<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 10:58:02
 * @LastEditors: Seven
 * @LastEditTime: 2021-08-17 15:20:55
-->
<template>
<HqDrawer ref="drawer"  title="商品详情" :visible.sync="showVisible" size="90%">
      <!-- <p slot="title" class="title">商品详情</p> -->
        <div class="ty-drawer-content">
        <div class="ty-try-header">
            <p>
                <span><i class="iconfont iconxieyiguanli"></i>{{this.name}}  {{this.commodityName}}</span>
                <span></span>
            </p>
        </div>
        <!-- default-expand-all="contentList" -->
        <div class="ty-page-list ty-drawer-list">
            <el-table
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :header-cell-style="{ background: '#e5f2ff' }"
                    style="width: 100%;margin:0 0 10px;border:1px solid #E1E4EB;"
                    highlight-current-row
                    max-height='700px'
                    :data="contentList"
                    default-expand-all
                    row-key="teacherContentId"
                    :tree-props="{children: 'children'}">
                <el-table-column :show-overflow-tooltip="true"  fixed prop="teacherContentId" label="id" type="" width="150" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  fixed prop="name" label="商品内容" min-width="280" class-name="ellipsis2 tree"  ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="typeStr" label="类型" min-width="150" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  prop="classTimesStr" label="课次数量" min-width="150" ></el-table-column>
<!--                <el-table-column :show-overflow-tooltip="true" prop="goodsType" label="详情" min-width="120">-->
<!--                    <template slot-scope="scope" >-->
<!--                        <span v-if="scope.row.children==undefined">-->
<!--                            <el-button size="mini" type="primary"  v-if="scope.row.goodsParent!=0" @click="goodsContentView(scope.row)">-->
<!--                                查看-->
<!--                            </el-button>-->
<!--                        </span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column :show-overflow-tooltip="true"  :formatter="formatFormAllData" prop="statusOrder" label="内容权限" min-width="150" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"  :formatter="formatFormAllData" prop="teachingSchedule" label="排课" ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"   prop="dueTimeStr" label="有效期" width="170" label-class-name="ty-ml8" ></el-table-column>
            </el-table>
        </div>
    </div>
    <!-- 侧滑关闭必须有 -->
    <div slot="footer">
        <el-button type="primary" @click="cancel()">返回</el-button>
    </div>
</HqDrawer>
</template>

<script>
export default {
    name:"goods-detail-drawer",
    props: {
        goodsId: String,
        orderId: String,
        visible: Boolean
    },
    watch:{
        visible( newValue ){
            this.showVisible = newValue
            if( newValue ){
                this.findGoodsContent(this.goodsId);
            }
        }
    },
    data(){
        return {
            showVisible: this.visible,
            name:'', //商品名
            commodityName:'',//规格名字
            contentList: [], //表格数据
            goodstypeArr:[
                {"id":1,"Text":"课程"},
                {"id":2,"Text":"真账实习"},
                {"id":3,"Text":"实训工具"},
                {"id":4,"Text":"会答"}],
            typeArr:[
                {"id":0,"Text":"课程"},
                {"id":1,"Text":"实习工具"},
                {"id":2,"Text":"实训工具"},
                {"id":3,"Text":"题库"},
                {"id":4,"Text":"资料"},
                {"id":5,"Text":"课程-面授"},
                {"id":6,"Text":"课程-双师"},
                {"id":7,"Text":"课程-直播"},
                {"id":8,"Text":"课程-录播"}],
            statusOrderArr:[
                {"id":0,"Text":"待开通"},
                {"id":1,"Text":"已开通"},
                {"id":2,"Text":"开通失败"}],
            statusCloseOrderArr :[
                {"id":0,"Text":"待关闭"},
                {"id":1,"Text":"已关闭"},
                {"id":2,"Text":"关闭失败"}],
            teachingScheduleArr:[
                {"id":0,Text:"待排课"},
                {"id":1,Text:"已排课"},
                {"id":2,Text:"已结课"}],
        }
    },
    mounted() {

            // this.name = decodeURI(getUrlParam("name"));
            // this.commodityName = decodeURI(getUrlParam("commodityName"));
        },
    methods: {
        init() {
          this.$refs.drawer.init()
        },
        findGoodsContent(){
            this.$fetch('findCourseAndContentByGoodsIdNew', {
                orderId: this.orderId
            }).then(response=>{
                let result = response.data;
                let orderGoodsVo = result["orderGoodsVo"] || {};
                this.name = orderGoodsVo["goodsName"]
                this.commodityName = orderGoodsVo["commodityName"];
                this.contentList=result["goodsTeacherContentVoList"] || [];
            }).catch(error=>{
                this.$message.error(`请求失败:${error.message}`);
            })
        },
        
        formatDate(row, column){
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            }
            let dt = new Date(data);
            let dtYear = dt.getFullYear()
            let dtMonth = dt.getMonth()+1<10 ? '0'+(dt.getMonth()+1) : dt.getMonth()+1;
            let dtDate = dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate();
            let dtHours = dt.getHours()<10 ? '0'+dt.getHours() : dt.getHours();
            let dtMin = dt.getMinutes()<10 ? '0'+dt.getMinutes() : dt.getMinutes();
            return dtYear + '-' + dtMonth + '-' + dtDate + ' ' + dtHours + ':' + dtMin;
        },
        formatFormAllData(row, column){
            switch (column.property) {
                case "type":
                    if(row.children!=undefined){
                        return this.formatDetailData(row[column.property],this.typeArr,1);//类型
                    }else{
                        return this.formatDetailData(row[column.property],this.typeArr);//类型
                    }

                case "statusOrder":
                    if (row.statusSwitch == 0) {
                        return this.formatDetailData(row[column.property],this.statusOrderArr,row.children);
                    }else if(row.statusSwitch == 1) {
                        return this.formatDetailData(row[column.property],this.statusCloseOrderArr,row.children);
                    }
                    break;//内容权限
                case "teachingSchedule":
                    if (row.statusSwitch == 0) {
                        return this.formatDetailData(row[column.property],this.teachingScheduleArr,row.children);
                    } else if(row.statusSwitch == 1) {
                        return "--";
                    }
                    break;//排课
                default:return "";
            }
        },
        formatDetailData(data,list,Type){
            if(Type==undefined&&data==undefined){
                return "--";
            }
            for (let i = 0; i < list.length; i++) {
                if(list[i].id==data){
                    return list[i]["Text"];
                }
            }
            return data;
        },
        cancel(){
          this.$emit("update:visible", false)    
          this.$refs.drawer.close()
        },
        goodsContentView(row){
            alert("查看详情"+row.name)
        }
    }
}
</script>

<style scoped lang="scss">
   [v-cloak] {
            display: none;
    }
    #app{
        background: #ffffff;
    }
    .iconxieyiguanli{
        color: #9bacbf;
        margin-right: 8px;
    }
    .ty-try-header{
        // padding: 24px 30px 24px 48px;
    }
    .ty-try-header p:nth-child(1) span:nth-child(1){
        font-size: 14px;
        font-weight: 700;
        color: #666666;
    }
    .ty-try-header p:nth-child(1) span:nth-child(2){
        float: right;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }
    .ty-try-header p:nth-child(2){
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #999999;
        padding: 7px 0 0 22px;
    }

    .ty-drawer-content {
      width: 94%;
      margin: 0 auto;
    }

    v-deep .el-drawer__header {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #999;
    }

    .ty-drawer-footer {
      position: absolute;
      bottom: 24px;
      left: 0;
      text-align: center;
      width: 100%;
    }
</style>